<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="flex-column flex-gap-16 pb-25 pt-25 pl-2 pr-2">
    <div class="flex-row-between">
      <h1 class="text_s text_weight_accent">
        {{ $t('inboxPage.chatSection.infoSidebar.dealsInfo.title') }}
      </h1>
      <default-button
        v-if="deals.length"
        :title="$t('inboxPage.chatSection.infoSidebar.dealsInfo.buttons.newDeal')"
        icon-name="bx-plus"
        color="transparent"
        form="rectangle"
        size="s"
        :disabled="!pipelines.length"
        @action="openModalDeal('')"
      />
    </div>
    <div
      v-if="deals.length"
      class="flex-column flex-gap-8"
    >
      <crm-deal-card
        v-for="deal in deals"
        :key="deal._id"
        :deal="deal"
        @open-modal-deal="openModalDeal"
      />
    </div>
    <div
      v-if="!deals.length"
      class="flex-column-centered flex-gap-16"
    >
      <i class="bx bx-line-chart icon_size_24 color_tertiary" />
      <span class="color_tertiary text_s">
        {{ $t('inboxPage.chatSection.infoSidebar.dealsInfo.noDeals') }}
      </span>
      <default-button
        :title="$t('inboxPage.chatSection.infoSidebar.dealsInfo.buttons.newDeal')"
        icon-name="bx-plus"
        color="neutral"
        form="rectangle"
        size="m"
        :disabled="!pipelines.length"
        @action="openModalDeal('')"
      />
    </div>
    <crm-deal-modal
      :show.sync="showPipeDriveModal"
      :deal="editDeal"
      :is-inbox="true"
      :crms="crms"
      :owners="owners.filter(owner => owner._id)"
      :pipelines="pipelines"
      :currencies="currencies"
      :stages="stages"
      :selected-pipeline="selectedPipeline"
      :stage="selectedStage"
      :prospects="selectedProspects"
      :companies="selectedCompanies"
      :is-edit="isEdit"
      :is-remount="key"
      :selected-crm="selectedCrm"
      @create-deal="createDeal"
      @add-item="addItem"
      @delete-item="deleteItem"
      @delete-deal="confirmDeleteDeal"
      @select-crm="selectCrmInbox"
    />
    <crm-add-item-modal
      v-if="selectedCrm"
      :show.sync="showCrmAddItemModal"
      :is-companies="isCompanies"
      :crm="selectedCrm"
      :chosen-prospects.sync="selectedProspects"
      :chosen-companies.sync="selectedCompanies"
    />
    <confirm-changes-modal
      :show.sync="showConfirmModal"
      :title="$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.confirmChanges.title', { name: nextFunc })"
      :text="$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.confirmChanges.text', { name: nextFunc })"
      :cancel-text="$t('actions.cancel')"
      :primary-text="$t('actions.delete')"
      :next="() => {}"
      @save-changes="deleteFunc"
    />
  </div>
</template>
<script>
  import CrmPage from '@/views/integrations/CRM/CrmPage';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import CrmDealCard from '@/components/CRMIntegration/CrmDealCard.vue';
  import CrmDealModal from '@/modals/CrmModals/CrmDealModal';
  import CrmAddItemModal from '@/modals/CrmModals/CrmAddItemModal';
  import ConfirmChangesModal from '@/modals/ConfirmChangesModal';

  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import { mapGetters } from 'vuex';

  import { deleteDeals } from '@/api/crmIntegration';

  export default {
    name: 'DealsInfo',
    components: {
      DefaultButton,
      CrmDealCard,
      CrmDealModal,
      CrmAddItemModal,
      ConfirmChangesModal,
    },
    extends: CrmPage,
    mixins: [capitalizeFilterMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showPipeDriveModal: false,
        editDeal: {},
        selectDealIndex: 0,
        isEdit: false,
        key: false,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
      deals () {
        // Прохожусь по всем интеграция и создаю массив со всеми сделками
        return Object.values(this.profile.crm_deals).flat();
      },
      crms () {
        return Object.keys(this.account.crm_tokens).map(crmValue => {
          const name = crmValue === 'default'
            ? (this.capitalize(this.account.brand) + 'CRM')
            : this.capitalize(crmValue);
          return {
            name,
            _id: crmValue,
          };
        });
      },
    },
    async created () {
      this.selectedCrm = this.crms[0]._id;
      await this.setPipeline();
      this.selectedProspects = [this.profile];
    },
    methods: {
      async selectCrmInbox (crm) {
        this.selectedCrm = crm;
        await this.setPipeline(crm);
        this.key = !this.key;
      },
      nextSlide (value) {
        const newValue = this.selectDealIndex + value;
        if (newValue > -1 && this.selectDealIndex < this.deals.length) {
          this.selectDealIndex = newValue;
        }
      },
      openModalDeal (deal) {
        if (deal) {
          this.editDeal = deal;
          this.selectedCrm = deal.platform;
          this.isEdit = true;
          this.selectedProspects = deal.crm_profiles;
          this.selectedProspects.forEach(element => {
            if (element.external_id) {
              element = Object.assign(element, {});
              element._id = element.external_id;
            }
          });
          this.selectedCompanies = deal.crm_companies;
          this.selectedCompanies.forEach(element => {
            if (element.external_id) {
              element = Object.assign(element, {});
              element._id = element.external_id;
            }
          });
          this.key = !this.key;
        } else {
          this.isEdit = false;
          this.selectedProspects = [this.profile];
        }

        this.showPipeDriveModal = true;
      },
      updateDeal (deal) {
        const profile = Object.assign(this.profile, {});
        const deals = profile.crm_deals[this.selectedCrm];
        const dealIndex = this.profile.crm_deals[this.selectedCrm].findIndex(el => el._id === deal._id);
        if (dealIndex > -1) {
          deals.splice(dealIndex, 1);
          deals.push(deal);
        }
        this.$emit('update-profile-prop', { crm_deals: profile.crm_deals });
      },
      addDeal (deal) {
        const profile = Object.assign(this.profile, {});
        const deals = profile.crm_deals[this.selectedCrm];
        deals.push(deal);
        this.$emit('update-profile-prop', { crm_deals: profile.crm_deals, status: 'interested' });
      },
      async deleteDeal () {
        try {
          this.showCrmModal = false;
          this.showPipeDriveModal = false;
          this.loaded = false;
          await deleteDeals(
            this.selectedCrm,
            this.selectedDeal._id
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
