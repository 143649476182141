/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import i18n from '@/i18n';

export const normalizeNameByStatusFilterMixin = {
  filters: {
    normalizeNameByStatus: function (status) {
      switch (status) {
        case 'new': {
          return i18n.t('status.new');
        }
        case 'request_sent': {
          return i18n.t('status.contacted');
        }
        case 'connected': {
          return i18n.t('status.connected');
        }
        case 'unconnectable': {
          return i18n.t('status.unreachable');
        }
        case 'fetched_email': {
          return i18n.t('status.gotEmail');
        }
        case 'viewed_message': {
          return i18n.t('status.viewedMessage');
        }
        case 'visited_link': {
          return i18n.t('status.visitedLink');
        }
        case 'got_reply': {
          return i18n.t('status.replied');
        }
        case 'contacted': {
          return i18n.t('status.contacted');
        }
        case 'interested': {
          return i18n.tc('common.lead');
        }
        case 'stuck': {
          return i18n.t('status.pending');
        }
        case 'lead': {
          return i18n.tc('common.customer');
        }
        case 'acceptance_rate': {
          return i18n.t('status.accepted');
        }
        case 'response_rate': {
          return i18n.t('statistics.replyRate');
        }
        case 'open_rate': {
          return i18n.t('statistics.emailOpenRate');
        }
        case 'click_rate': {
          return i18n.t('statistics.emailClickRate');
        }
        case 'message_click_rate': {
          return i18n.t('statistics.messageClickRate');
        }
        case 'bounce_rate': {
          return i18n.t('statistics.bounceRate');
        }
        default: {
          return i18n.t('status.excluded');
        }
      }
    },
  },
};
