<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-modal
    :show.sync="innerShowModal"
    :show-buttons="false"
    :title="$t('workspaceSettings.settingsList.members.inviteModal.title')"
    name="users-invite-modal"
    height="auto"
    width="480px"
  >
    <template #default>
      <validation-observer v-slot="{ passes }">
        <form
          ref="vldParent"
          class="vld-parent"
          @submit.prevent="passes(sendInvite)"
        >
          <default-segmented-control
            :button-options="segmentedControlItems"
            :chosen-segmentation="inviteMethod"
            class="mb-3 users-invite-modal__glider"
            max-width="320px"
            @change="setInviteMethod"
          />

          <tag-input
            v-if="inviteMethod === 'email'"
            :label="$t('workspaceSettings.settingsList.members.inviteModal.input.label')"
            :value="formData.inputValue"
            :tags="formData.emails"
            @add-tag="(tags) => handleEmailsInput(tags)"
            @input="formData.inputValue = $event"
          />

          <div
            v-else-if="inviteMethod === 'code' && selectedCodeInvite?.code"
            class="users-invite-modal__code"
          >
            <span class="users-invite-modal__code__text">
              {{ selectedCodeInvite?.code }}
            </span>

            <div class="users-invite-modal__code__controls">
              <default-button
                :title="$t('actions.copy')"
                icon-name="bx-copy"
                form="rectangle"
                color="primary"
                size="s"
                @action="copyValue(selectedCodeInvite?.code)"
              />

              <default-button
                :title="$t('actions.refresh')"
                icon-name="bx-refresh"
                form="rectangle"
                color="neutral"
                size="s"
                @action="handleRenewCodeInvite(selectedCodeInvite)"
              />
            </div>
          </div>

          <div class="mt-3 users-invite-modal__footer">
            <default-select
              :description="$t('workspaceSettings.settingsList.members.inviteModal.inviteAs')"
              :value="permissions.find(e => e.value === selectedPermissions).name"
              :items="permissions"
              read-only
              input-size="xs"
              class="users-invite-modal__permissions-select"
              @select="selectPermissions"
            />

            <default-button
              v-if="inviteMethod === 'email'"
              type="submit"
              :title="$t('workspaceSettings.settingsList.members.inviteModal.buttons.sendInvites')"
              color="primary"
              form="rectangle"
              size="m"
            />
          </div>
        </form>
      </validation-observer>
    </template>
  </default-modal>
</template>
<script>
  import defaultModal from '@/modals/defaultModal.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect.vue';
  import TagInput from '@/components/base/uiKit.v3/TagInput.vue';
  import DefaultSegmentedControl from '@/components/base/uiKit.v3/DefaultSegmentedControl.vue';
  import { ValidationObserver } from 'vee-validate';
  import { mapGetters } from 'vuex';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { sendEmailInvite, getCodeInvites, renewCodeInvite } from '@/api/spaceMethods';

  export default {
    name: 'UsersInviteModal',
    components: {
      defaultModal,
      TagInput,
      DefaultButton,
      ValidationObserver,
      DefaultSelect,
      DefaultSegmentedControl,
    },
    mixins: [differentFunctionsMixin, loaderMixin],
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        innerShowModal: this.showModal,
        formData: {
          emails: [],
          inputValue: '',
        },
        inviteMethod: 'email',
        permissions: [
          {
            name: this.$tc('common.member'),
            value: 'member',
          },
          {
            name: this.$tc('common.admin'),
            value: 'admin',
          },
        ],
        selectedPermissions: 'member',
        codeInvites: [],
        segmentedControlItems: [
          {
            text: this.$tc('common.email'),
            value: 'email',
          },
          {
            text: this.$tc('common.inviteCode'),
            value: 'code',
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
      selectedCodeInvite: function () {
        return this.codeInvites.find((code) => {
          return code.granted_access === this.selectedPermissions;
        });
      },
    },
    watch: {
      innerShowModal (value) {
        if (value !== this.showModal) {
          this.$emit('update:showModal', value);
        }
      },
      showModal (value) {
        if (!value) {
          this.resetState();
        }
        if (value !== this.innerShowModal) {
          this.innerShowModal = value;
        }
      },
    },
    async mounted () {
      this.codeInvites = await this.fetchInviteCodes();
    },
    methods: {
      resetState () {
        this.selectedPermissions = 'member';
        this.inviteMethod = 'email';
        this.formData.emails = [];
        this.formData.inputValue = '';
      },
      async fetchInviteCodes () {
        return await getCodeInvites(this.account._id);
      },
      handleEmailsInput (tags) {
        this.formData.emails = tags;
      },
      setInviteMethod (method) {
        this.inviteMethod = method;
      },
      selectPermissions (permissions) {
        this.selectedPermissions = permissions.value;
      },
      async handleRenewCodeInvite (inviteCode) {
        try {
          this.loaded = false;
          await renewCodeInvite(this.account._id, inviteCode._id);
          this.codeInvites = await this.fetchInviteCodes();
          this.$noty.success(
            this.$t('workspaceSettings.notifications.inviteCodeRefreshed')
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async sendInvite () {
        this.loaded = false;
        try {
          const emails = [...this.formData.emails];
          this.formData.inputValue && emails.push(this.formData.inputValue);
          await sendEmailInvite(this.account._id, emails, this.selectedPermissions);
          this.$emit('invite');
          this.$noty.success(
            this.$t('workspaceSettings.notifications.invitationSent')
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>

<style lang="scss">
.users-invite-modal {
  &__code {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    &__text {
      font-size: 28px;
      line-height: 32px;
      font-weight: 600;
      letter-spacing: 4px;
      cursor: text;
    }

    &__controls {
      display: flex;
      justify-content: center;
      gap: 8px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__permissions-select {
    width: 180px;

    input {
      cursor: pointer;
    }
  }
}
</style>
