<template>
  <div class="parent-size">
    <ul
      v-if="messages.length"
      class="flex-column flex-gap-16"
    >
      <card-messages
        v-for="message in messages"
        :key="message._id"
        :class="[
          'list-messages__item',
          { 'flex-align-self-end flex-align-items-end': message.is_me },
        ]"
        :message="message"
        @edit-approval="$emit('edit-approval', $event)"
        @delete-message="$emit('delete-message', $event)"
      />
    </ul>
    <ul
      v-else-if="loading"
      class="flex-column flex-gap-16"
    />
    <div
      v-else-if="!messages.length"
      class="flex-column-centered parent-size flex-gap-16 color_tertiary"
    >
      <i class="bx bx-envelope icon_size_60" />
      <p class="text_s text_weight_accent">
        {{ $t('inboxPage.chatSection.notifications.noMessages') }}
      </p>
    </div>
  </div>
</template>
<script>
  import CardMessages from '@/components/inboxPage/chat/messages/CardMessages';

  export default {
    name: 'ListMessages',
    components: {
      CardMessages,
    },
    props: {
      messages: {
        type: Array,
        default: null
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
