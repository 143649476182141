import { render, staticRenderFns } from "./DeleteConfirmationModal.vue?vue&type=template&id=5728a56c&scoped=true&"
import script from "./DeleteConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./DeleteConfirmationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5728a56c",
  null
  
)

export default component.exports