<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="push-to-crm flex-column flex-gap-8">
    <div class="flex flex-gap-8 flex-align-items-center">
      <span class="text_s color_tertiary">
        {{ $t('campaignsPage.editor.steps.pushToCrm.selectCrm.label').concat(':') }}
      </span>
      <default-select
        class="ml-25"
        :value="selectedCrm.name || ''"
        :items="crms"
        data-closable="crmStep"
        :icon="'bx-line-chart'"
        :read-only="true"
        size="s"
        :placeholder="$t('campaignsPage.editor.steps.pushToCrm.selectCrm.placeholder')"
        @select="selectCrm"
      />
    </div>
    <div class="flex flex-gap-8 flex-align-items-center">
      <span class="text_s color_tertiary">
        {{ $t('campaignsPage.editor.steps.pushToCrm.chooseOwner.label').concat(':') }}
      </span>
      <default-select
        :key="selectedOwner.name"
        :value="selectedOwner.name || ''"
        :items="owners"
        data-closable="crmOwner"
        :icon="selectedOwner?.img ? '' : 'bx-user'"
        :left-img="selectedOwner?.img || ''"
        size="s"
        :read-only="true"
        max-height="300"
        :placeholder="$t('campaignsPage.editor.steps.pushToCrm.chooseOwner.placeholder')"
        @select="selectOwner"
      />
    </div>
  </div>
</template>
<script setup>
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect.vue';
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { getUsers } from '@/api/crmIntegration';
  import { capitalize } from '@/mixins/capitalizeFilterMixin';
  import { useI18n } from '@/i18n';

  const props = defineProps({
    crmOwner: {
      type: String,
      default: '',
    },
    crm: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits([
    'update:crm-owner',
    'update:crm',
  ]);

  const { t } = useI18n();
  const store = useStore();
  const selectedCrm = ref({
    name: t('campaignsPage.editor.steps.pushToCrm.selectCrm.defaultValue'),
  });
  const selectedOwner = ref({
    name: t('campaignsPage.editor.steps.pushToCrm.chooseOwner.defaultValue'),
  });
  const account = computed(() => store.getters['account/account']);
  const users = ref([]);

  const crms = computed(() => {
    return Object.keys(account.value.crm_tokens).map(crmValue => {
      const name = crmValue === 'default'
        ? (capitalize(account.value.brand) + 'CRM')
        : capitalize(crmValue);
      return {
        name,
        _id: crmValue,
      };
    });
  });

  const owners = computed(() => {
    return users.value.map(owner => {
      return { name: owner.name, _id: owner._id, img: owner.img };
    });
  });
  async function getOwners (crm) {
    users.value = await getUsers(crm);
  }
  async function getCrms () {
    if (!props.crm && !props.crmOwner) {
      selectedCrm.value = crms.value[0];
      await getOwners(crms.value[0]._id);
    } else {
      selectedCrm.value = crms.value.find(crm => crm._id === props.crm);
      await getOwners(props.crm);
    }
    selectedOwner.value = owners.value[0];
    emit('update:crm', selectedCrm.value._id);
    emit('update:crm-owner', selectedOwner.value._id);
  }

  onMounted(async () => { await getCrms(); });
  const selectOwner = (owner) => {
    selectedOwner.value = owner;
    emit('update:crm-owner', owner._id);
  };
  const selectCrm = async (crm) => {
    selectedCrm.value = crm;
    emit('update:crm', crm._id);
    await getOwners(crm._id);
    selectOwner(owners.value[0]);
  };
</script>
<style lang="scss">
.push-to-crm {
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 16px;
  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
</style>
