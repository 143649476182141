<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="vldParent"
    class="vld-parent"
  >
    <div class="crm">
      <crm-page-header
        :owners="otherOwners"
        :pipelines="otherPipelines"
        :all-pipelines="pipelines"
        :crms="otherCrms"
        :selected-pipeline="selectedPipeline"
        :selected-owner="selectedOwner"
        :selected-status="selectedStatus"
        @select-status="selectStatus"
        @select-owner="selectOwner"
        @add-pipeline="addPipeline"
        @rename-pipeline="renamePipeline"
        @delete-pipeline="confirmDeletePipline"
        @select-pipeline="selectPipeline"
        @select-crm="selectCrm"
        @open-crm-deal-modal="openCrmDealModal"
        @search-name="searchName"
        @refresh="refreshCrm"
      />
      <div
        v-if="pipelines && pipelines.length && loaded"
        class="crm__main"
      >
        <crm-stages-table
          class="crm__table"
          :stages="stages"
          :loaded="loaded"
          :selected-pipeline="selectedPipeline"
          :selected-crm="selectedCrm"
          @add-stage="saveStage"
          @update-stage="saveStage"
          @delete-stage="confirmDeleteStage"
          @change-order="changeOrder"
          @load-deals="getDeals"
          @open-crm-deal-modal="openCrmDealModal"
        />
      </div>

      <div
        v-else-if="pipelines && !pipelines.length && loaded"
        class="parent-size flex-column-centered flex-gap-16"
      >
        <i class="bx bx-line-chart color_secondary icon_size_60" />
        <p class="text_m">
          {{ $t('pipelinesPage.table.noPipelines', { crm: selectedCrm }) }}
        </p>
        <default-button
          v-if="selectedCrm === 'default'"
          size="l"
          color="primary"
          form="rectangle"
          :title="$t('pipelinesPage.table.actions.addPipeline')"
          icon-name="bx-plus"
          @action="addPipeline"
        />
      </div>
    </div>

    <crm-add-pipeline-modal
      :show.sync="showCrmAddPipelineModal"
      :is-edit="isEditPipeline"
      :selected-pipeline="selectedPipeline"
      @save-pipeline="savePipeline"
    />
    <confirm-changes-modal
      :show.sync="showConfirmModal"
      :title="$t('pipelinesPage.modals.delete.title', { name: nextFunc })"
      :text="$t('pipelinesPage.modals.delete.bodyText', { name: nextFunc })"
      :cancel-text="$t('actions.cancel')"
      :primary-text="$t('actions.delete')"
      :next="() => {}"
      @save-changes="deleteFunc"
    />
    <crm-deal-modal
      :show.sync="showCrmModal"
      :deal="selectedDeal"
      :owners="owners.filter(owner => owner._id)"
      :pipelines="pipelines"
      :currencies="currencies"
      :selected-owner="selectedOwner"
      :stages="stages"
      :selected-crm="selectedCrm"
      :selected-pipeline="selectedPipeline"
      :stage="selectedStage"
      :prospects="selectedProspects"
      :companies="selectedCompanies"
      :is-edit="isEdit"
      @create-deal="createDeal"
      @add-item="addItem"
      @delete-item="deleteItem"
      @delete-deal="confirmDeleteDeal"
    />
    <crm-add-item-modal
      :show.sync="showCrmAddItemModal"
      :is-companies="isCompanies"
      :crm="selectedCrm"
      :chosen-prospects.sync="selectedProspects"
      :chosen-companies.sync="selectedCompanies"
    />
  </div>
</template>

<script>
  import CrmStagesTable from '@/components/CRMIntegration/CrmStagesTable.vue';
  import CrmPageHeader from '@/components/CRMIntegration/CrmPageHeader.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import ConfirmChangesModal from '@/modals/ConfirmChangesModal';

  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';

  import {
    updateDeal,
    updateDealOrder,
    createPipeline,
    deletePipeline,
    crmInfo,
    getStages,
    getDeals,
    deleteStage,
    deleteDeals,
    addStage,
    updateStage,
    getDeal,
    updatePipeline,
    refreshCrm
  } from '@/api/crmIntegration';

  import CrmDealModal from '@/modals/CrmModals/CrmDealModal';
  import CrmAddItemModal from '@/modals/CrmModals/CrmAddItemModal';
  import CrmAddPipelineModal from '@/modals/CrmModals/CrmAddPipelineModal';

  export default {
    name: 'CrmPage',
    components: {
      CrmDealModal,
      CrmStagesTable,
      CrmPageHeader,
      CrmAddItemModal,
      CrmAddPipelineModal,
      DefaultButton,
      ConfirmChangesModal,
    },
    mixins: [loaderMixin],
    data () {
      return {
        q: '',
        owners: [],
        stages: [],
        pipelines: [],
        currencies: [],
        selectedDeal: {},
        selectedOwner: {},
        selectedPipeline: {},
        selectedStatus: {
          name: this.$t('pipelinesPage.header.selects.deals.open'),
          _id: 'open',
        },
        selectedStage: {},
        limitDeals: 8,
        selectedProspects: [],
        selectedCompanies: [],
        selectedCrm: this.$route.params.integration || '',
        isCompanies: false,
        isEdit: false,
        showCrmModal: false,
        stagesForInput: [],
        nextFunc: '',
        isEditPipeline: false,
        showCrmChangeProspectModal: false,
        showCrmChangeCompanyModal: false,
        showCrmAddPipelineModal: false,
        showCrmAddItemModal: false,
        showConfirmModal: false,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
      otherCrms () {
        return Object.keys(this.account.crm_tokens).filter(
          (e) => e !== this.selectedCrm
        );
      },
      otherOwners () {
        if (this.owners.length) {
          const owners = [...this.owners];
          return owners.filter((e) => e._id !== this.selectedOwner._id);
        }
        return [];
      },
      otherPipelines () {
        if (this.pipelines.length) {
          const pipelines = [...this.pipelines];
          return pipelines.filter((e) => e._id !== this.selectedPipeline._id);
        }
        return [];
      },
    },
    watch: {
      showCrmModal () {
        if (!this.showCrmModal) {
          this.selectedProspects = [];
          this.selectedCompanies = [];
        }
      },
    },
    async created () {
      this.selectedCrm = this.$route.params.integration;
      if (Object.keys(this.account.crm_tokens).includes(this.selectedCrm)) {
        await this.setPipeline();
      }
    },
    methods: {
      async openCrmDealModal (stage, deal) {
        if (deal) {
          try {
            this.isEdit = true;
            this.selectedDeal = await getDeal(this.selectedCrm, deal._id);
            this.selectedProspects = this.selectedDeal.crm_profiles;
            this.selectedCompanies = this.selectedDeal.crm_companies;
            this.selectedProspects.forEach((element) => {
              if (element.external_id) {
                element._id = element.external_id;
              }
            });
            this.selectedCompanies.forEach((element) => {
              if (element.external_id) {
                element._id = element.external_id;
              }
            });
          } catch (e) {
            this.$noty.error(e.message);
          }
        } else {
          this.isEdit = false;
          this.selectedDeal = {};
        }
        this.selectedStage = stage;
        this.showCrmModal = !this.showCrmModal;
      },
      async saveStage (stageName, probability, pipelineId, dealId) {
        try {
          this.loaded = false;
          if (dealId) {
            // если есть аргументы, нзначит стейдж существует и надо апдейтить
            await updateStage(
              this.selectedCrm,
              stageName,
              probability,
              pipelineId,
              dealId
            );
          } else {
            await addStage(
              this.selectedCrm,
              stageName,
              probability,
              pipelineId,
              this.stages.length
            );
          }
        } catch (e) {
          this.$noty.error(
            this.$t('notifications.fillAllFields')
          );
        } finally {
          this.loaded = true;
        }
      },
      async choosePipelines (pipeline) {
        this.selectedPipeline = pipeline || {};
        await this.setStage();
      },
      addPipeline () {
        this.showCrmAddPipelineModal = !this.showCrmAddPipelineModal;
      },
      renamePipeline () {
        this.isEditPipeline = true;
        this.showCrmAddPipelineModal = !this.showCrmAddPipelineModal;
      },
      async savePipeline (name, isEdit) {
        if (name) {
          if (!isEdit) {
            this.showCrmAddPipelineModal = false;
            await createPipeline(this.selectedCrm, { name });
          } else {
            this.selectedPipeline.name = name;
            this.showCrmAddPipelineModal = false;
            await updatePipeline(this.selectedCrm, this.selectedPipeline);
          }
        } else {
          this.$noty.error(
            this.$t('notifications.addName')
          );
        }
      },
      async deleteFunc () {
        if (this.nextFunc === 'deal') {
          await this.deleteDeal();
        }
        if (this.nextFunc === 'stage') {
          await this.deleteStage();
        }
        if (this.nextFunc === 'pipeline') {
          await this.deletePipeline();
        }
      },
      async deletePipeline () {
        await deletePipeline(this.selectedCrm, this.selectedPipeline._id);
        await this.setPipeline();
      },
      async deleteStage () {
        try {
          this.loaded = false;
          await deleteStage(
            this.selectedCrm,
            this.selectedStage._id,
            this.selectedStage
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      confirmDeleteDeal (deal) {
        this.selectedDeal = deal;
        this.nextFunc = 'deal';
        this.showConfirmModal = true;
      },
      confirmDeleteStage (stage) {
        this.nextFunc = 'stage';
        this.selectedStage = stage;
        this.showConfirmModal = true;
      },
      confirmDeletePipline () {
        this.nextFunc = 'pipeline';
        this.showConfirmModal = true;
      },
      async deleteDeal () {
        try {
          this.showCrmModal = false;
          this.loaded = false;
          await deleteDeals(this.selectedCrm, this.selectedDeal._id);
          await this.getDeals({ stageId: this.selectedStage._id, offset: this.selectedStage.crm_deals.length - 1 }, 1);
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      addItem (isCompanies) {
        this.isCompanies = isCompanies;
        this.showCrmAddItemModal = !this.showCrmAddItemModal;
      },
      deleteItem (item, isCompanies) {
        this.isCompanies = isCompanies;
        if (this.isCompanies) {
          this.selectedCompanies = this.selectedCompanies.filter(
            (e) => e._id !== item._id
          );
        } else {
          this.selectedProspects = this.selectedProspects.filter(
            (e) => e._id !== item._id
          );
        }
      },
      async changeOrder (deal, i) {
        if (deal.added) {
          try {
            const response = await updateDeal(
              this.selectedCrm,
              deal.added.element._id,
              {
                stage_id: this.stages[i].external_id,
                pipeline_id: this.selectedPipeline.external_id,
              }
            );
            this.updateDeal(response);
          } catch (e) {
            this.$noty.error(e.message);
          }
        } else {
          const stage = this.stages.find(
            (stage) => stage._id === deal.removed.element.crm_stage._id
          );
          await this.getDeals({ stageId: stage._id, offset: stage.crm_deals.length - 1 }, 1);
        }
        if (deal.moved) {
          try {
            await updateDealOrder(
              this.selectedCrm,
              deal.moved.element._id,
              deal.moved.newIndex
            );
          } catch (e) {
            this.$noty.error(e.message);
          }
        }
      },
      async setPipeline (chosenCrm) {
        chosenCrm = chosenCrm || this.selectedCrm;
        try {
          this.loaded = false;
          this.selectedPipeline = {};
          this.selectedOwner = {};
          this.owners = [];
          const crm = await crmInfo(chosenCrm);
          this.currencies = crm.crm_currencies;
          this.owners = crm.crm_owners;
          this.owners.unshift({
            name: this.$t('pipelinesPage.header.selects.owner.all'),
            _id: '',
          });
          if (chosenCrm !== 'pipedrive') {
            this.owners.push({
              name: this.$t('pipelinesPage.header.selects.owner.noOwner'),
              _id: 'null',
            });
          }
          this.selectedOwner = this.owners[0];
          this.pipelines = crm.crm_pipelines;
          await this.selectPipeline(this.pipelines[0] || {}, chosenCrm || '');
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async selectCrm (crm) {
        this.selectedCrm = crm;
        await this.$router.push({
          name: 'crm-manage',
          params: {
            integration: crm,
          },
        });
        this.selectedCrm = crm;
        await this.setPipeline(crm);
      },
      async selectPipeline (pipeline, chosenCrm) {
        this.selectedPipeline = pipeline || {};
        await this.setStage(chosenCrm || '');
      },
      async selectOwner (owner) {
        this.selectedOwner = owner;
        await this.setStage();
      },
      async selectStatus (status) {
        this.selectedStatus = status;
        await this.setStage();
      },
      async searchName (name) {
        this.q = name.trim();
        await this.setStage();
      },
      async createDeal () {
        this.selectedProspects = [];
      },

      async getDeals ({ stageId, offset }, limit = this.limitDeals) {
        const stage = this.stages.find((s) => s._id === stageId);

        if (stage.loading || stage.loadMore === false) return;

        stage.loading = true;
        try {
          const deals = await getDeals(this.selectedCrm, stageId, {
            status: this.selectedStatus._id ? this.selectedStatus._id : '',
            pipeline_id: this.selectedPipeline._id
              ? this.selectedPipeline._id
              : '',
            owner_id: this.selectedOwner._id ? this.selectedOwner._id : '',
            q: this.q,
            limit,
            offset,
          });
          stage.loadMore = deals.length !== 0;

          this.updateDeals(stageId, deals);
          stage.loading = false;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          stage.loading = false;
        }
      },

      updateDeals (stageId, deals) {
        const stage = this.stages.find((s) => s._id === stageId);
        stage.crm_deals = [...stage.crm_deals, ...deals];
      },

      async getStages (crm) {
        const isNeedDeals = ['default', 'pipedrive', 'hubspot'].some(
          (e) => e === this.$route.params.integration
        );
        this.stages = await getStages(crm || this.selectedCrm, {
          with_deals: isNeedDeals,
          status: this.selectedStatus._id ? this.selectedStatus._id : '',
          pipeline_id: this.selectedPipeline._id ? this.selectedPipeline._id : '',
          owner_id: this.selectedOwner._id ? this.selectedOwner._id : '',
          q: this.q,
          limit: this.limitDeals,
        });
      },

      async setStage (chosenCrm) {
        try {
          this.loaded = false;
          await this.getStages(chosenCrm || '');
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },

      updateDeal (deal) {
        const stages = this.stages;
        const stageIndex = stages.findIndex(
          (stage) => stage._id === deal.crm_stage._id
        );
        const dealIndex = stages[stageIndex]?.crm_deals?.findIndex(
          (el) => el._id === deal._id
        );

        if (dealIndex > -1) {
          stages[stageIndex].crm_deals.splice(dealIndex, 1, deal);
        } else {
          // Сработает при изменение stages у сделки.
          stages.find((stage, indexStage) => {
            const indexDeal = stage?.crm_deals.findIndex(
              (el) => el._id === deal._id
            );
            if (indexDeal > -1) {
              if (
                deal.crm_owner &&
                stages[stageIndex]?.crm_deals[dealIndex]?.crm_owner._id !==
                deal.crm_owner._id
              ) {
                stages[indexStage]?.crm_deals.splice(indexDeal, 1);
                stages[stageIndex]?.crm_deals.push(deal);
              } else {
                stages[indexStage]?.crm_deals.splice(indexDeal, 1);
                stages[stageIndex]?.crm_deals.push(deal);
              }
            }
            return null;
          });
        }
      },
      async  addDeal (deal) {
        const stage = this.stages.find(
          (stage) => stage._id === deal.crm_stage._id
        );
        if (stage.loadMore === false) {
          stage.crm_deals.push(deal);
          this.$noty.success('Deal added');
        }
      },
      updateStage (stage) {
        const stages = this.stages;
        const existingIndex = stages.findIndex((el) => el._id === stage._id);

        if (existingIndex > -1) {
          stages.splice(existingIndex, 1, stage);
        } else {
          stages.push(stage);
        }
      },
      async refreshCrm () {
        try {
          this.loaded = false;
          await refreshCrm(this.selectedCrm || this.$route.params.integration);
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
    sockets: {
      updated_deal (response) {
        this.updateDeal(JSON.parse(response));
      },
      added_deal (response) {
        this.addDeal(JSON.parse(response));
      },
      deleted_deal (response) {
        this.updateDeal(JSON.parse(response));
      },
      updated_deal_positions (response) {
        this.updateDeal(JSON.parse(response));
      },
      async updated_pipeline (response) {
        const pipeline = JSON.parse(response);
        const existingIndex = this.pipelines.findIndex(
          (el) => el._id === pipeline._id
        );
        if (existingIndex > -1) {
          this.pipelines.splice(existingIndex, 1, pipeline);
        }
      },
      async added_pipeline (response) {
        this.pipelines.push(JSON.parse(response));
        this.selectedPipeline = JSON.parse(response);
        await this.setStage();
      },
      deleted_pipeline (response) {
        const pipeline = JSON.parse(response);
        const existingIndex = this.pipelines.findIndex(
          (el) => el._id === pipeline._id
        );
        if (existingIndex > -1) {
          this.pipelines.splice(existingIndex, 1);
        }
      },
      updated_stage (response) {
        const stage = JSON.parse(response);
        this.updateStage(stage);
      },
      added_stage (response) {
        const stage = JSON.parse(response);
        this.stages.push(stage);
      },
      deleted_stage (response) {
        const stage = JSON.parse(response);
        const existingIndex = this.stages.findIndex((el) => el._id === stage._id);
        if (existingIndex > -1) {
          this.stages.splice(existingIndex, 1);
        }
      },
    },
  };
</script>

<style lang="scss">
.crm {
  @include parent-size;
  display: grid;
  grid-template-rows: auto 1fr;

  &__dot {
    background: var(--secondary-text-color);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }

  &__header {
    padding: 12px;
    gap: 4px;
    display: flex;
    align-items: center;
    background: var(--table-bg);
    z-index: 1;
    justify-content: space-between;

    &-left-side {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &-right-side {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &__main {
    overflow: scroll;
    display: flex;
    gap: 16px;
    width: 100%;
    position: relative;
  }
  // &__filter-block {
  //   position: relative;
  //   transition: 1s;
  //   margin-left: auto;
  //   &_hide {
  //     margin-right: -340px;
  //   }
  // }
  &__select-crm {
    position: relative;
    display: flex;
    flex-flow: column;
    transition: 300ms;
  }

  &__selected-crm {
    z-index: 99;
    padding: 3px 4px 0px 4px;
    position: relative;
    cursor: pointer;

    &-arrow {
      position: absolute;
      top: 15px;
      right: 16px;
      font-size: 8px;
      color: #fff;
      z-index: 101;
      transition: 400ms;

      &_open {
        transform: rotate(180deg);
      }
    }
  }
  &__select-items {
    position: absolute;
    padding: 0px 4px;
    background: var(--background-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06),
      0px 0px 8px rgba(10, 32, 51, 0.05);
    transition: 300ms;

    &_open {
      top: 40px;
      opacity: 1;
      z-index: 9;
    }

    &_close {
      top: 0px;
      opacity: 0;
      z-index: 0;
    }
  }

  &__select-item {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
  &__owner-avatar {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    &_icon {
      font-size: 16px;
    }
  }

  &__select-owner {
    padding: 10px 12px;
    border: 1px solid #dce5ed;
    position: relative;
    border-radius: 8px;
    font-size: 13px;
    transition: 300ms;

    & input {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  &__selected-owner {
    display: flex;
    align-items: center;
    z-index: 99;
    gap: 8px;
  }

  &__selected-owner-arrow {
    &_open {
      transform: rotate(180deg);
    }
  }

  &__select-owner-items {
    position: absolute;
    width: 100%;
    background: var(--background-color);
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06),
      0px 0px 8px rgba(10, 32, 51, 0.05);
    transition: 300ms;
    left: 0;

    &_open {
      top: 40px;
      z-index: 9;
    }

    &_close {
      display: none;
    }
  }

  &__select-owner-item {
    display: flex;
    cursor: pointer;
    gap: 8px;
    align-items: center;
    padding: 8px;
    z-index: 0;

    &:hover {
      background: var(--hover-secondary);
      border-radius: 8px;
    }
  }
  // &__filters {
  //   position: sticky;
  //   top: 16px;
  //   max-width: 320px;
  // }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;

    &_hidden {
      max-width: 80%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
