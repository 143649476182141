/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { TEMPLATES } from '@/utils/workflows/templates';

export const templatesMixin = {
  data () {
    return {
      templates: TEMPLATES.value,
    };
  },
  computed: {
    onlyTemplates () {
      return this.templates.reduce((prev, section) => {
        return [...prev, ...section.data];
      }, []);
    },
    templatesRegexps () {
      const templatesRegexps = [];
      const allTemplates = this.onlyTemplates;
      allTemplates.forEach(template => {
        if (!template.disabled) {
          const regexString = template.fallback ? `{${template.value}(:[^}]+)}` : `{${template.value}(:[^}]+)?}`;
          templatesRegexps.push(new RegExp(regexString));
        }
      });
      return templatesRegexps;
    },
  },

};
