<template>
  <div class="flex-column flex-gap-4">
    <div class="flex-row flex-gap-4">
      <default-input
        icon="bx-search"
        :placeholder="$t('placeholders.search')"
        size="xs"
        :value="searchData.qeury"
        :is-fulled="true"
        :debounce="300"
        @input="makeSearch"
      />
      <filters-block
        :filters="searchData.filters"
        @update-filters="$emit('update-filters', $event)"
      />
    </div>

    <div>
      <default-tooltip
        data-closable="groupFilters"
        :items="groupData"
        list-position="right"
        :has-triangle="false"
        :is-fulled="true"
        @choose="groupDataHandler"
      >
        <template #default>
          <default-button
            :title="groupButtonName"
            icon-name="bxs-chevron-down"
            color="tertiary"
            form="rectangle"
            size="m"
            :has-right-icon="true"
            :is-fulled="true"
          />
        </template>
      </default-tooltip>
    </div>
  </div>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import FiltersBlock from '@/components/base/filtersBlock/FiltersBlock';
  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';

  export default {
    name: 'FiltersDialogs',
    components: {
      DefaultInput,
      DefaultTooltip,
      DefaultButton,
      FiltersBlock,
    },
    mixins: [capitalizeFilterMixin],
    props: {
      searchData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        groupData: [
          {
            value: 'all',
            name: this.$t('inboxPage.chatsSidebar.header.filters.all'),
          },
          {
            value: 'unread',
            name: this.$t('inboxPage.chatsSidebar.header.filters.unread'),
          },
          {
            value: 'replied',
            name: this.$t('inboxPage.chatsSidebar.header.filters.replies'),
          },
          {
            value: 'starred',
            name: this.$t('status.starred'),
          },
          {
            value: 'archived',
            name: this.$t('status.archieved'),
          },
        ],
      };
    },
    computed: {
      groupButtonName () {
        return this.groupData.find(e => e.value === this.searchData.group).name;
      },
    },
    methods: {
      groupDataHandler (item) {
        this.$emit('update-group', item.value);
      },
      makeSearch (e) {
        this.$emit('update-query', e);
      },
    },
  };
</script>
