/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

export const workflowSteps = computed(() => ({
  li_connect: i18n.t('actions.sendConnectionRequest'),
  li_send_message: i18n.t('actions.sendMessage'),
  li_send_inmail: i18n.t('actions.sendInMail'),
  li_actions: i18n.t('actions.performAction'),
  li_connect_by_email: i18n.t('actions.sendConnectionByEmail'),
  send_email: i18n.t('actions.sendEmail'),
  enrich_profile: i18n.t('actions.enrichProfile'),
  send_webhook: i18n.t('actions.sendWebhook'),
  push_to_crm: i18n.t('actions.pushToCrm'),
}));
