<template>
  <header class="flex-row-between">
    <div class="flex-row flex-gap-8">
      <default-avatar
        size="s"
        :photo-link="profile.photo_link"
        :status-color="profileStatusColor"
      />
      <div class="flex-column flex-gap-4">
        <p class="text_s text_weight_accent">
          {{ profile.full_name }}
        </p>
        <custom-switchers-list
          class="flex flex-gap-4"
          button-type="circle"
          :button-style="{
            padding: '4px 12px',
          }"
          :list="switcherList"
          :selected="activeMessagesGroup"
          @select="$emit('update:activeMessagesGroup', $event)"
        />
      </div>
    </div>
    <div class="flex flex-gap-8 margin-left flex-align-items-center">
      <router-link
        :to="{name: 'inbox',
              params: {
                profileId: profileIdFromInbox,
              },}"
      >
        <default-button
          v-if="profileIdFromInbox"
          class="inbox-page__button"
          :title="$t('inboxPage.chatSection.header.buttons.openWithInbox')"
          form="ellipse"
          color="primary"
          size="s"
        />
      </router-link>
      <default-tooltip
        data-closable="profileActions"
        :items="profileActions"
        list-position="right"
        :has-triangle="false"
        :is-fulled="true"
        @choose="profileActionsHandler"
      >
        <template #default>
          <default-button
            icon-name="bx-dots-vertical-rounded"
            color="tertiary"
            form="square"
            size="m"
          />
        </template>
      </default-tooltip>
    </div>
    <input-modal
      name="forwardModal"
      :title="$t('inboxPage.chatSection.header.buttons.forwardTo')"
      :primary-button-text="$t('inboxPage.chatSection.header.buttons.forward')"
      :show.sync="modals.forward"
      :tags.sync="recentlyUsedEmails"
      :placeholder="$tc('common.email')"
      height="auto"
      @send="sendForward"
    />
  </header>
</template>

<script setup>
// HeaderInbox
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import variables from '@/styles/_variables.module.scss';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import CustomSwitchersList from '@/components/base/CustomSwitchersList';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import InputModal from '@/modals/inputModal';
  import { useRecentEmails } from '@/mixins/usedEmailsVue3';
  import { useConfig } from '@/mixins/useConfig';
  import { forwardToEmail } from '@/api/profilesMethods';
  import { useI18n } from '@/i18n';
  import { computed, ref } from 'vue';
  import { useStore } from '@/store';
  import { useNoty } from '@/lib/useNoty';
  class ActionData {
    /**
     * @param {string} name
     * @param {'tag'|'forward'|'blacklist'} type
     * @param {string} value
     * @param {string} iconName
     */
    constructor (type, name, value, iconName) {
      this.name = name;
      this.type = type;
      this.value = value;
      this.iconName = iconName;
    }
  }

  const { t, tc } = useI18n();

  const profileActionsData = [
    // // new ActionData(
    //   'tag',
    //   t('inboxPage.chatSection.header.buttons.markAsLead'),
    //   'interested',
    //   'bx-label'
    // ),
    // // new ActionData(
    //   'tag',
    //   t('inboxPage.chatSection.header.buttons.markAsCustomer'),
    //   'lead',
    //   'bx-user'
    // ),
    new ActionData(
      'tag',
      t('inboxPage.chatSection.header.buttons.notInterested'),
      'not_interested',
      'bx-block'
    ),
    new ActionData(
      'forward',
      t('inboxPage.chatSection.header.buttons.forwardToEmail'),
      'forward',
      'bx-envelope'
    ),
    new ActionData(
      'blacklist',
      t('actions.removeFromBlacklist'),
      'ignored',
      'bx-minus'
    ),
    new ActionData(
      'blacklist',
      t('actions.addToBlackList'),
      'blacklisted',
      'bx-plus'
    ),
    // // new ActionData(
    //   'blacklist',
    //   t('actions.resetBlacklistStatus'),
    //   'default',
    //   'bx-reset'
    // ),
  ];

  const props = defineProps({
    activeMessagesGroup: {
      type: String,
      required: true,
    },
    hasOldMessagesButton: {
      type: Boolean,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    profileIdFromInbox: {
      type: String,
      default: '',
    },
  });
  const { $noty } = useNoty();
  const store = useStore();
  const emit = defineEmits(['update-profile-prop', 'update:hasOldMessagesButton', 'blacklist']);
  const switcherList = ref([
    {
      value: 'messages',
      name: t('servicesNames.linkedIn'),
    },
    {
      value: 'emails',
      name: tc('common.email', 2),
    },
  ]);
  const modals = ref({ forward: false });
  const { recentlyUsedEmails } = useRecentEmails();
  const { $config } = useConfig();
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
  const profileStatusColor = computed(() => variables[`status-${props.profile.tag}`] || variables[`status-${props.profile.status}`]);
  const profileActions = computed(() => {
    const actions = profileActionsData
      .filter(data => {
        switch (data.type) {
        case 'tag':
          if (data.value === 'not_interested') {
            return ['interested', 'lead'].includes(props.profile.tag);
          }
          return props.profile.tag !== data.value;
        case 'forward':
          return $config.brand !== 'yourleadtool';
        case 'blacklist':
          if (data.value === 'ignored') {
            return props.profile.tag === 'excluded';
          }
          return props.profile.blacklist_status !== data.value;
        }
        return true;
      });
    if (props.hasOldMessagesButton && props.activeMessagesGroup === 'messages') {
      actions.unshift(
        new ActionData(
          'messages',
          t('inboxPage.chatSection.header.buttons.showOldMessages'),
          'old_messages',
          'bx-message'
        )
      );
    }
    return actions;
  });
  /** @param {ActionData} item */
  const profileActionsHandler = (item) => {
    switch (item.type) {
    case 'tag':
      emit('update-profile-prop', { tag: item.value });
      break;
    case 'forward':
      modals.value.forward = true;
      break;
    case 'messages':
      emit('update:hasOldMessagesButton', false);
      break;
    case 'blacklist':
      emit('update-blacklist', item.value);
    }
  };
  const sendForward = (email) => {
    try {
      // if (!emailRegex.test(email)) {
      //   throw new Error('Email is wrong');
      // }
      if (!recentlyUsedEmails.value.includes(email)) {
        recentlyUsedEmails.value.push(email);
      }
      forwardToEmail(props.profile._id, selectedCredential.value._id, email);
      $noty.success(
        t('inboxPage.chatSection.header.notifications.forwardedToEmail')
      );
    } catch (e) {
      $noty.error(e.message);
    }
  };
</script>
