<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <section
    ref="vldParent"
    class="vld-parent video-constructor__card"
  >
    <article
      v-if="videoInfo.title"
      class="mb-3"
    >
      <p class="video-constructor__title">
        {{ videoInfo.title }}
      </p>
    </article>

    <article
      v-if="videoInfo.description"
      class="mb-3"
    >
      <p class="text_xl">
        {{ videoInfo.description }}
      </p>
    </article>

    <article class="mb-3">
      <div
        v-if="scenario && !scenario.preview_src"
        class="video-constructor__preview"
      >
        <p
          v-if="!scenario._id"
          class="color_primary"
        >
          {{ $t('videoConstructor.preview.info.saveScenario') }}
        </p>
        <div
          v-else-if="scenario.is_preview_wip"
          class="flex-column-centered flex-gap-16 color_primary"
        >
          <i class="bx bx-loader loader-icon icon_size_32" />
          <p class="text_s">
            {{ $t('videoConstructor.preview.info.previewProcessing') }}
          </p>
        </div>
        <default-button
          v-else
          :title="$t('videoConstructor.preview.buttons.record')"
          color="primary"
          form="rectangle"
          size="m"
          icon-name="bx bx-video-recording"
          @action="recordPreview"
        />
      </div>
      <video-player
        v-else-if="playerOptions"
        class="mb-3"
        :options="playerOptions"
      />
      <default-button
        v-if="scenario?.preview_src"
        :title="$t('videoConstructor.preview.buttons.copyLink')"
        color="transparent"
        form="rectangle"
        type="button"
        size="m"
        :has-paddings="false"
        @action="copyValue(scenario.preview_src)"
      />
      <default-button
        v-if="scenario && scenario.preview_src && !scenario.is_preview_wip"
        :title="$t('videoConstructor.preview.buttons.rerecord')"
        color="transparent"
        form="rectangle"
        type="button"
        size="m"
        :has-paddings="false"
        @action="recordPreview"
      />
    </article>

    <div v-if="videoInfo.calendar_link">
      <div v-if="isCalendlyLink">
        <article
          v-if="videoInfo.call_to_action_title"
          class="mb-3"
        >
          <p class="text_xl">
            {{ videoInfo.call_to_action_title }}
          </p>
        </article>

        <article class="mb-3">
          <iframe
            :src="videoInfo.calendar_link"
            class="video-constructor__calendar"
          />
        </article>
      </div>
      <div
        v-else
        class="flex-row-centered"
      >
        <default-button
          tag="a"
          target="_blank"
          :href="videoInfo.calendar_link"
          :title="videoInfo.call_to_action_title"
          color="primary"
          form="rectangle"
          size="l"
          @action="recordPreview"
        />
      </div>
    </div>
    <!-- Иначе будет падать ошибка Dropzone -->
    <!-- Dropzone.autoDiscover = false - не помогло -->
    <div
      v-show="false"
      id="dropzone"
    />
  </section>
</template>
<script>
  import 'video.js/dist/video-js.css';
  import { videoPlayer } from 'vue-video-player';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { getInfo, getPreviewTemplate, recordPreview } from '@/api/scenario';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { mapGetters } from 'vuex';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';

  export default {
    name: 'ConstructorPage',
    components: {
      videoPlayer,
      DefaultButton,
    },
    mixins: [loaderMixin, differentFunctionsMixin],
    props: {
      scenario: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        playerOptions: null,
        videoInfo: {
          title: '',
          description: '',
          call_to_action_title: '',
          calendar_link: '',
          extension: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
        scenarioList: 'scenario/scenarioList',
      }),
      isCalendlyLink () {
        return this.videoInfo.calendar_link.includes('https://calendly.com');
      },
    },
    async mounted () {
      if (this.scenario) {
        if (this.scenario.preview_src) {
          const previewId = this.scenario.preview_id;
          await this.setInfo(previewId);
        } else {
          await this.setPreviewTemplate();
        }
      } else {
        await this.setInfo(this.$route.params.videoId);
      }
    },
    methods: {
      async setPreviewTemplate () {
        try {
          this.loaded = false;
          const settings = this.scenario.video_settings;
          const templatesObj = {
            title_template: settings.title_template,
            description_template: settings.description_template,
            call_to_action_title: settings.call_to_action_title,
            calendar_link: settings.calendar_link,
          };
          const transformedTemplates = await getPreviewTemplate(this.selectedCredential._id, templatesObj);
          this.videoInfo = Object.assign(this.videoInfo, transformedTemplates);
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async setInfo (id) {
        this.loaded = false;
        try {
          const info = await getInfo(id);
          this.videoInfo.calendar_link = info.scenario.video_settings.calendar_link;
          this.videoInfo.title = info.title;
          this.videoInfo.description = info.description;
          this.videoInfo.call_to_action_title = info.call_to_action_title;
          this.playerOptions = {
            responsive: true, // позволяет настраивать breakpoints
            loop: false, // воспроизводить видео сначала после того, как закончится
            fluid: true, // авто масштабирование относительно контейнера
            controls: true,
            playbackRates: [0.75, 1.0, 1.25, 1.5, 1.75, 2.0],
            sources: [{
              type: `video/${info.extension || 'webm'}`,
              src: `https://api.buzz.ai/public_api/videos/${id}`,
            }],
          };
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async recordPreview () {
        try {
          await recordPreview(this.scenario._id, this.selectedCredential._id);
          this.$store.commit('scenario/UPDATE_SCENARIO', { ...this.scenario, is_preview_wip: true });
          this.$emit('update', true, 'is_preview_wip');
          this.$noty.success(
            this.$t('videoConstructor.preview.notifications.requestForRecordingSent')
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
<style lang="scss">
.video-constructor {
  &__title {
    text-align: center;
    font-size: 29px;
    font-weight: $t-weight-accent;
  }

  &__calendar {
    border: 0;
    width: 100%;
    height: 1200px;
  }

  &__card {
   margin-bottom: 16px;
   padding: 24px;
   border-radius: 12px;
   background: $cl-white;
  }

  &__preview {
    @include flex-row-centered;
    width: 100%;
    min-height: 500px;
    border-radius: $border-radius-big;
    border: $border-dashed;
  }
}

@media (max-width: 765px) {
  .video-constructor__calendar {
    margin: 0 auto;
    border-radius: 8px;
    background: var(--background-color);
  }
}

.vjs-big-play-button { // центрирование кнопки из библиотики плеера
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%) !important;
}
</style>
