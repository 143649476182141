
function setTagScript (user) {
  window.ire && window.ire('identify', { customerId: user?._id || '', customerEmail: user?.email || '' });
}
function impactTrackScript (user, space = null) {
  window.ire && window.ire('trackConversion', 36177, {
    orderId: user._id,
    customerId: user._id,
    customerEmail: space ? space.email : user.email,
    customerStatus: space ? space.subscription.status : 'active',
    currencyCode: space ? space.subscription.currency : 'USD',
    items: [
      {
        subTotal: space ? space.subscription.amount.slice(0, space.subscription.amount - 2) : '',
        category: space ? 'subscription' : '',
        sku: space ? space.subscription.tier : '',
        quantity: 1,
        name: space ? space.name : '',
      },
    ],

  },
  {
    verifySiteDefinitionMatch: true,
  }
  );
}
export { setTagScript, impactTrackScript };
